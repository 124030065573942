const theme = {
  breakpoints: ['640px', '768px', '1024px', '1280px'],
  fontSizes: ['1rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem'],
  colors: {
    primary: '#753e9c',
    secondary: '#ffe100',
    primary50: '#A179BB',
    secondary50: '#FFE88D',
    grey: 'rgba(0, 0, 0, 0.2)',
    shade: 'rgba(0, 0, 0, 0.04)',
    background: '#fff',
    text: 'rgba(0, 0, 0, .8)'
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  sidebarWidth: {
    big: '375px',
    normal: '320px',
  },
  container: '75rem'
}

export default theme
