import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../config/theme'

const Wrapper = styled.svg`
  position: ${props => props.fixed ? 'static' : 'absolute'};
  stroke: currentColor;
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
`

const icons = {
  logo: {
    shape: (
      <>
        <path fill="white" d="M33 4a8 8 0 015 2 8 8 0 013 6v2a14 14 0 01-4 27H12a8 8 0 01-8-8 8 8 0 012-6L27 7a9 9 0 016-3m0-4a13 13 0 00-9 4L4 24a12 12 0 00-4 9 12 12 0 0012 12h25a18 18 0 008-34 12 12 0 00-4-8 12 12 0 00-8-3z"/>
        <path fill={theme.colors.primary} fillRule="evenodd" d="M6 27L27 7a8 8 0 0111-1 8 8 0 013 6v21a8 8 0 01-8 8H12a8 8 0 01-8-8 8 8 0 012-6z"/>
        <circle fill={theme.colors.secondary} cx="37.2" cy="27.1" r="13.5"/>
      </>
    ),
    viewBox: '0 0 55 45',
  },
  logoInvert: {
    shape: (
      <>
        <path stroke={theme.colors.primary} fillRule="evenodd" d="M6 27L27 7a8 8 0 0111-1 8 8 0 013 6v21a8 8 0 01-8 8H12a8 8 0 01-8-8 8 8 0 012-6z"/>
        <circle fill={theme.colors.primary} cx="37.2" cy="27.1" r="13.5"/>
      </>
    ),
    viewBox: '0 0 55 45',
  },
  circle: {
    shape: (
      <circle cx="15" cy="15" r="14.5"/>
    ),
    viewBox: '0 0 30 30',
  },
  triangle: {
    shape: (
      <path d="M2 19L19 2a6 6 0 0110 5v16c0 3-3 6-6 6H7a6 6 0 01-5-10z"/>
    ),
    viewBox: '0 0 40 30',
  },
  card: {
    shape: (
      <>
        <rect width="313.3" height="208.6" rx="25"/>
        <path fill="#fff" d="M543.14 251.4a7.72 7.72 0 015.24 2 8 8 0 012.62 5.92v2.15a13.5 13.5 0 01-3.5 26.53h-24.8a8.26 8.26 0 01-8.38-7.61 8 8 0 012.34-6.05l20.41-20.41a8.63 8.63 0 016.07-2.53m0-4a12.69 12.69 0 00-8.9 3.7l-20.41 20.41a12 12 0 00-3.5 9.07A12.19 12.19 0 00522.7 292h24.8a17.5 17.5 0 007.5-33.32 12 12 0 00-3.92-8.25 11.73 11.73 0 00-7.92-3z" transform="translate(-487 -220.71)"/>
        <path fill={theme.colors.primary} fillRule="evenodd" d="M516.66 274.34l20.41-20.41a8.26 8.26 0 0111.31-.54 8 8 0 012.62 5.92v20.61a8.08 8.08 0 01-8.08 8.08H522.7a8.26 8.26 0 01-8.38-7.61 8 8 0 012.34-6.05z" transform="translate(-487 -220.71)"/>
        <circle fill={theme.colors.secondary} cx="60.5" cy="53.79" r="13.5"/>
        <path fill="#fff" d="M0 124h314v60a25 25 0 01-25 25H25a25 25 0 01-25-25v-60z" opacity=".2"/>
        <g opacity=".7">
          <path stroke="#fff" strokeWidth="3" strokeLinecap="round" d="M751 268a5 5 0 000-7M756 273a12 12 0 000-17M761 278a18 18 0 000-27" transform="translate(-487 -221)"/>
        </g>
      </>
    ),
    viewBox: '0 0 314 209',
  },
  clauer: {
    shape: (
      <>
        <path fill={theme.colors.primary} fillRule="evenodd" d="M73 228a56 56 0 0033-90l-24-29a35 35 0 00-60 11l-12 35a56 56 0 0063 73z"/>
        <path fill="#fff" fillRule="evenodd" d="M55 131a9 9 0 10-10-7 9 9 0 0010 7z"/>
        <path fill="none" stroke={theme.colors.secondary} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="8" d="M71 79l-17 44"/>
        <ellipse cx="1023.6" cy="309.1" fill="none" stroke={theme.colors.secondary} strokeMiterlimit="10" strokeWidth="8" rx="43.5" ry="44" transform="rotate(-10 -941 5572)"/>
        <path fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="14" d="M73 228h0a56 56 0 0033-90l-24-29a35 35 0 00-60 11l-12 35a56 56 0 0063 73z" opacity=".2"/>
      </>
    ),
    viewBox: '0 0 127 236',
  },
  contactless: {
    shape: (
      <>
      <path stroke={theme.colors.secondary} strokeWidth="20" strokeLinecap="round" d="M519 935a88 88 0 000-127M554 971c28-26 45-61 45-100s-17-74-45-100M481 900a40 40 0 000-57" transform="translate(-285 -759)"/>
      <path fill={theme.colors.secondary} fillRule="evenodd" d="M447 885c7 0 13-6 13-14a14 14 0 00-28 0c0 8 7 14 15 14z" transform="translate(-285 -759)"/>
      <path stroke={theme.colors.secondary} strokeWidth="20" strokeLinecap="round" d="M413 843a40 40 0 000 57M377 808a88 88 0 000 127" transform="translate(-285 -759)"/>
      <path stroke={theme.colors.secondary} strokeWidth="20" strokeLinecap="round" d="M342 771c-28 26-45 61-45 100s17 74 45 100" transform="translate(-285 -759)"/>
      </>
    ),
    viewBox: '0 0 326 224',
  },
  report: {
    shape: (
      <>
        <path fill={theme.colors.secondary} d="M935 397h186a9 9 0 010 18H935a9 9 0 110-18zM935 428h138a9 9 0 110 18H935a9 9 0 010-18zM935 467h138a9 9 0 110 18H935a9 9 0 010-18zM935 566h393a9 9 0 010 18H935a9 9 0 010-18zM935 595h393a9 9 0 010 18H935a9 9 0 110-18zM935 624h393a9 9 0 110 18H935a9 9 0 010-18zM935 651h393a9 9 0 110 18H935a9 9 0 010-18zM935 680h393a9 9 0 110 18H935a9 9 0 010-18zM935 709h393a9 9 0 010 18H935a9 9 0 010-18zM935 738h393a9 9 0 010 18H935a9 9 0 110-18zM935 766h393a9 9 0 010 18H935a9 9 0 010-18zM935 794h393a9 9 0 110 18H935a9 9 0 010-18zM935 823h393a9 9 0 010 18H935a9 9 0 010-18zM935 852h393a9 9 0 010 18H935a9 9 0 010-18zM935 526h50a9 9 0 010 18h-50a9 9 0 010-18zM935 953h98a9 9 0 010 18h-98a9 9 0 010-18zM1106 526h50a9 9 0 010 18h-50a9 9 0 010-18zM1278 526h50a9 9 0 010 18h-50a9 9 0 110-18zM1278 898h50a9 9 0 010 18h-50a9 9 0 110-18zM1016 526h50a9 9 0 010 18h-50a9 9 0 010-18zM1190 526h47a9 9 0 010 18h-47a9 9 0 010-18zM1190 898h47a9 9 0 010 18h-47a9 9 0 010-18zM1190 467h137a9 9 0 110 18h-137a9 9 0 010-18z" transform="translate(-926 -397)"/>
      </>
    ),
    viewBox: '0 0 411 574',
  },
  nuvol: {
    shape: (
      <>
      <path d="M313 516h197a67 67 0 010 135H313a67 67 0 010-135z" transform="translate(-177 -402)"/>
      <path d="M274 456h137a97 97 0 010 195H274a97 97 0 010-195z" transform="translate(-177 -402)"/>
      <ellipse cx="215.5" cy="117" rx="116.9" ry="117"/>
      </>
    ),
    viewBox: '0 0 400 249',
  },
  phone: {
    shape: (
      <>
        <rect fill="" width="193.8" height="399.2" x="1" y="1" stroke="#ddd" strokeMiterlimit="10" strokeWidth="1" rx="28.2"/>
        <rect width="176.3" height="381.7" x="10" y="10" fill="#ddd" rx="18.8"/>
        <path fill="#fff" fillRule="evenodd" d="M51 9h95v4a11 11 0 01-11 10H61a11 11 0 01-11-10z"/>
      </>
    ),
    viewBox: '0 0 196 401',
  },
  laptop: {
    shape: (
      <>
        <rect fill="#fff" stroke="#ddd" strokeWidth="2" width="505" height="330" x="48.5" y="1" rx="15.6"/>
        <rect fill="#fff" stroke="#ddd" strokeeWidth="2" width="600" height="13" x="1" y="330.5" rx="6.5"/>
        <path fill="#ddd" fillRule="evenodd" d="M1493.35 999.44h107v1.5a1.5 1.5 0 01-1.5 1.5h-104a1.49 1.49 0 01-1.49-1.5z" transform="translate(-1245.85 -667.94)"/>
        <path fill="#e6e6e6" d="M73.91 24.14h455.24v22.39H73.91z"/>
        <path fill="#f2f2f2" fillRule="evenodd" d="M1359.16 701.51h403.62a6.11 6.11 0 010 12.21h-403.62a6.11 6.11 0 110-12.21z" transform="translate(-1245.85 -671.94)"/>
        <ellipse cx="96.34" cy="35.68" fill="#39b54a" rx="2.72" ry="2.71"/>
        <ellipse cx="88.86" cy="35.68" fill="#fbb03b" rx="2.72" ry="2.71"/>
        <ellipse cx="81.39" cy="35.68" fill="#c1272d" rx="2.72" ry="2.71"/>
      </>
    ),
    viewBox: '0 0 602 345',
  },
  phone_s: {
    shape: (
      <>
        <rect fill={theme.colors.secondary} width="173" height="339" x="1" y="1" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" rx="16.3"/>
        <path stroke="#fff" strokeWidth="2" d="M69 21h37"/>
        <circle stroke="#fff" strokeWidth="2" cx="87" cy="319" r="13"/>
      </>
    ),
    viewBox: '0 0 175 341',
  },
  laptop_s: {
    shape: (
      <>
        <path fill={theme.colors.secondary} stroke="#fff" strokWidth="2" fillRule="evenodd" d="M1 21V8a7 7 0 017-7h439a7 7 0 017 7v13z"/>
        <circle fill="#fff" cx="26.4" cy="11.5" r="2.7"/>
        <circle fill="#fff" cx="19" cy="11.5" r="2.7"/>
        <circle fill="#fff" cx="11.5" cy="11.5" r="2.7"/>
        <path fill={theme.colors.secondary} stroke="#fff" strokeWidth="2" d="M1 21h453v308H1z"/>
      </>
    ),
    viewBox: '0 0 455 331',
  },
  verified: {
    shape: (
      <>
        <path d="M30.7278984 5.27203125C27.3281484 1.87235156 22.8079688 0 18 0 13.1919609 0 8.67171094 1.87235156 5.27203125 5.27203125 1.87228125 8.67178125 0 13.1920312 0 18s1.87228125 9.3281484 5.27203125 12.7278984C8.67178125 34.1276484 13.1919609 36 18 36c4.8079688 0 9.3281484-1.8723516 12.7278984-5.2721016C34.1276484 27.3281484 36 22.8079688 36 18c0-4.8079688-1.8723516-9.32814844-5.2721016-12.72796875zM18 33.890625C9.23786719 33.890625 2.109375 26.7621328 2.109375 18 2.109375 9.23786719 9.23786719 2.109375 18 2.109375c8.7621328 0 15.890625 7.12849219 15.890625 15.890625 0 8.7621328-7.1284922 15.890625-15.890625 15.890625z"/>
        <path d="M26.6879099 12.3045263c-.416049-.4060466-1.0907347-.4060466-1.5067838.0000693l-9.4091587 9.1845164-4.9530225-4.8347698c-.4160491-.4061159-1.09073473-.4061159-1.50685483 0-.41612009.406116-.41612009 1.0646936 0 1.4708789l5.70641443 5.5701746c.20806.2030926.4807615.3046043.7533919.3046043s.5454029-.101581.7533919-.3046043l10.1626216-9.9199905c.4161201-.406116.4161201-1.0646936 0-1.4708789z"/>
      </>
    ),
    viewBox: '0 0 37 37',
  },
  device: {
    shape: (
      <>
        <g id="Layer_2" data-name="Layer 2">
          <g id="objects"><g id="device">
            <g class="cls-2">
              <g class="cls-2">
                <g class="cls-2" clip-path="url(#clip-path)">
                  <path d="M5 97v14a16 16 0 01-5-12V85a16 16 0 005 12" class="cls-4"/>
                </g>
              </g>
              <g class="cls-2">
                <g class="cls-2" clip-path="url(#clip-path-2)">
                  <path fill="#442658" d="M244 135v14a16 16 0 01-6 12l1-14a16 16 0 005-12"/>
                  <path d="M239 147l-1 14-1 1 1-14 1-1" class="cls-7"/>
                </g>
              </g>
              <path fill="#3b214c" d="M122 214v14L5 111V97l117 117z"/>
              <path fill="#46275b" d="M238 148l-1 14-87 68v-14l88-68z"/>
              <path fill="#6c3d8d" d="M239 123c8 7 7 18-1 25l-88 68c-8 6-20 5-28-2L5 97c-7-7-6-18 2-25L94 4c9-6 21-5 28 2z"/>
              <g class="cls-2">
                <g class="cls-2" clip-path="url(#clip-path-3)">
                  <path d="M150 216v14a20 20 0 01-3 2v-14a19 19 0 003-2" class="cls-7"/>
                  <path fill="#3f2452" d="M147 218v14a21 21 0 01-5 2v-15a22 22 0 005-1"/>
                  <path d="M142 219v15a22 22 0 01-20-6v-14a22 22 0 0020 5" class="cls-4"/>
                </g>
              </g>
              </g>
              <g id="contactless">
              <path fill="yellow" d="M118 60a27 27 0 018 21 3 3 0 01-3 3 4 4 0 01-3-1 3 3 0 01-1-2 21 21 0 00-6-17 27 27 0 00-18-8 4 4 0 01-2-1 3 3 0 01-1-2 3 3 0 013-3 35 35 0 0123 10zM91 106a3 3 0 010 2 3 3 0 01-3 3 35 35 0 01-23-10 27 27 0 01-8-21 3 3 0 013-3 4 4 0 013 1 3 3 0 011 2 21 21 0 006 17 27 27 0 0018 8 4 4 0 013 1z" class="cls-13"/>
              <path fill="yellow" d="M92 91a3 3 0 011 3 3 3 0 01-4 3 18 18 0 01-12-5 14 14 0 01-4-11 3 3 0 014-3 4 4 0 012 1 3 3 0 011 2 8 8 0 003 6 10 10 0 006 3 4 4 0 013 1zM90 121a3 3 0 011 2 3 3 0 01-4 3 56 56 0 01-19-4 47 47 0 01-16-11 41 41 0 01-10-15 39 39 0 01-2-17 3 3 0 014-3 4 4 0 012 1 3 3 0 011 2 34 34 0 002 15 35 35 0 008 13 41 41 0 0014 9 49 49 0 0016 4 4 4 0 013 1zM94 78a4 4 0 010 6 5 5 0 01-7-1 4 4 0 011-5 5 5 0 016 0zM105 70a14 14 0 014 11 3 3 0 01-3 3 4 4 0 01-3-2 3 3 0 01-1-2 8 8 0 00-2-6 10 10 0 00-7-3 4 4 0 01-2-1 3 3 0 01-1-2 3 3 0 014-3 18 18 0 0111 5zM131 49a41 41 0 0110 16 40 40 0 012 17 3 3 0 01-4 3 4 4 0 01-2-1 3 3 0 01-1-2 34 34 0 00-2-15 35 35 0 00-8-13 40 40 0 00-14-9 48 48 0 00-16-4 4 4 0 01-3-1 3 3 0 01-1-2 3 3 0 014-3 56 56 0 0119 4 47 47 0 0116 10z" class="cls-13"/>
              </g>
            </g>
          </g>
        </g>
      </>
    ),
    viewBox: '0 0 244 234'
  },
  ona: {
    shape: (
      <g fill="#FFDE57" fill-rule="evenodd">
        <circle cx="688.5" cy="688.5" r="306.5" fill-opacity=".3"/>
        <circle cx="690.5" cy="690.5" r="485.5" fill-opacity=".2"/>
        <circle cx="691.5" cy="691.5" r="691.5" fill-opacity=".1"/>
      </g>
    ),
    viewBox: '0 0 1383 1383'
  }
}

const SVG = ({ stroke, fill, width, icon, left, right, top, rotate, fixed }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={`${width}rem`}
    left={left}
    right={right}
    top={top}
    transform={`rotate(${rotate ? rotate : 0})`}
    fixed={fixed}
  >
    {icons[icon].shape}
  </Wrapper>
)

export default SVG

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  width: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  rotate: PropTypes.string
}

SVG.defaultProps = {
  stroke: 'transparent',
  fill: 'none',
  left: '0%',
  top: '0%'
}
